p { margin: 0.2em; }

.wodrix-container {
    width: 500px;
    height: 100%;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: flex-start; 
    gap: 10px; 
    display: inline-flex;  
    border:0;
} 

.wodrix-container .wodrix-LOGO {
    align-self: stretch;
    height: 42px;
    margin-top: 5px;
    margin-bottom: 2px;
}

.wodrix-container .game-frame {
    align-self: stretch;
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
}

.wodrix-container .main-screen {
    flex: 1 1 0; 
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
}

.wodrix-container .main-screen .stats-container {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
}

.wodrix-container .game-frame .Board {
    align-self: stretch;
    width: 132px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
    display: inline-flex;
}
.wodrix-container .game-frame .Board .Window {
    align-self: stretch;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1px;
    padding-bottom: 5px;
    background-color: #F3CA20;
    justify-content: flex-start;
    align-items: center;
    /*display: none;*/
}

.wodrix-container .game-frame .Board .Window .Wordlist {
    flex: 1 1 0;
    align-self: stretch;
    word-wrap: break-word;
    font-size: 15px;
}
.popup-content {
    width: 30%;
}
.column {
    float: left;
    width: 25%;
    padding: 5px;
  }
  
  /* Clear floats after image containers */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  .PopupButton {
    background: #000000;
    color: black;
    border:0;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 3x;
    padding-bottom:3px;
  }

@media only screen and (max-width: 600px) {
    .wodrix-container {
        width: 100%;
        height: 100%;
        gap: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .wodrix-container .game-frame {
        gap: 3px;
    }
    .wodrix-container .main-screen {
        gap: 3px;
    }
    .wodrix-container .game-frame .Board {
        width: 65px;
        gap: 1px;
        margin-right: 5px;
    }
    .wodrix-container .game-frame .Board .Window .Wordlist {
        font-size: 14px;
        width: 100%;
    }
    .wodrix-container .main-screen .stats-container {
        gap: 5px;
    }
    .popup-content {
        width: 55%;
    }
    .column {
        float: left;
        width: 33.33%;
        padding: 5px;
      }
      
      /* Clear floats after image containers */
      .row::after {
        content: "";
        clear: both;
        display: table;
      }
}

